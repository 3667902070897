







































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import DatePicker from "@/components/date-picker.vue";
import {
    apiPhysicalVouchersMaterialList,
} from "@/api/card/physical_vouchers";

@Component({
    components: {
        DatePicker,
    },
})
export default class print_type_2 extends Vue {

    material_list: any[] = [];
    material_key_list:any[] = [];

    @Prop({ default: () => ({}) }) value: any; //form

    created() {
        this.getMaterialList();
    }

    getMaterialList() {
        apiPhysicalVouchersMaterialList({
        }).then((res) => {
            this.material_list = res;
            this.arrayToKey(this.material_list)
        });
    }

    arrayToKey(array:any) {
        array.forEach((element:any) => {
            this.material_key_list[element.value] = element.price
        });
    }

    //计算金额
    materialChange(value:any) {
        this.value.monovalent = this.material_key_list[value]
        this.value.amount = this.material_key_list[value] * this.value.number
        this.value.total_cost = this.value.amount + 200
    }

}
