


















































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class print_type_1 extends Vue {
    @Prop({ default: () => ({}) }) value: any; //form
}
