























































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import print_type_1 from "./components/print_type_1.vue";
import print_type_2 from "./components/print_type_2.vue";
import MaterialSelect from "@/components/material-select/index.vue";

import { apiPhysicalVouchersAdd } from "@/api/card/physical_vouchers";
import { validator } from "@/utils/util";

@Component({
    components: {
        print_type_1,
        print_type_2,
        MaterialSelect
    },
})
export default class reserveCardAdd extends Vue {
    steps: number = 1;
    type: any = [
        {
            title: "次票券",
            label: 1,
            discrble: "按次兑换，包括电影次票券和N选1兑换券",
        },
        {
            title: "储值券",
            label: 2,
            discrble:
                "储值兑换，包括全场通用储值券·指定品类储值券、指定商品储值券",
        },
    ];
    printing_method: any = [
        {
            title: "自行印刷",
            label: 1,
            discrble:
                "选择自行印刷，可直接将劵密发送至本平台预留邮箱及手机号，同时可将印刷模版文件导出",
        },
        {
            title: "平台印刷",
            label: 2,
            discrble: "通过平台印刷方式，平台仅收取印刷成本费用",
        },
    ];
    // 添加商城表单数据
    form: any = {
        type: 1, //券类型
        printing_method: 1, //印刷方式
        number: "", //备券数量
        remark: "", //备注
        coupon_password_email: "", //邮件
        keys_phone: "", //电话
        email_content: "", //邮件内容
        email_topic: "", //邮件主题
        material: "", //券材质
        size: "", //券尺寸
        effect: "", //券面
        monovalent: "", //印刷单价
        amount: "", //印刷金额
        is_urgent: 1, //是否加急
        is_expiration_date: 1, //是否印刷有效期
        total_cost: "", //印刷总费用
        start_expiration_date: "", //券有效期
        end_expiration_date: "",
        other_notes: "", //其他说明
        contact: "", //联系人
        contact_number: "", //联系人手机号
        mailing_address: "", //邮寄地址
        shipping_methods: 1, //配送方式,
        source_file: "",
        preview_file: "",
    };

    // 表单校验
    rules = {
        number: [
            { required: true, message: "请输入备券数量", trigger: "blur" },
            {
                validator: (required: object, value: number, callback: any) =>
                    validator("number_1", value, callback),
                message: "请输入不小于1的整数",
                trigger: "blur",
            },
        ],
        coupon_password_email: [
            { required: true, message: "请输入邮箱", trigger: "blur" },
            {
                validator: (required: object, value: number, callback: any) =>
                    validator("email", value, callback),
                message: "请输入正确的邮箱地址",
                trigger: "blur",
            },
        ],
        keys_phone: [
            { required: true, message: "请输入手机号", trigger: "blur" },
            {
                validator: (required: object, value: number, callback: any) =>
                    validator("phone", value, callback),
                message: "请输入正确的手机号",
                trigger: "blur",
            },
        ],
        email_content: [
            { required: true, message: "请输入邮件内容", trigger: "blur" },
        ],
        email_topic: [
            { required: true, message: "请输入邮件主题", trigger: "blur" },
        ],
        material: [
            { required: true, message: "请选择券材质", trigger: "change" },
        ],
        size: [{ required: true, message: "请选择券尺寸", trigger: "change" }],
        effect: [
            { required: true, message: "请选择券面效果", trigger: "change" },
        ],
        start_expiration_date: [
            { required: true, message: "请选择券有效期", trigger: "change" },
        ],
        end_expiration_date: [
            { required: true, message: "请选择券有效期", trigger: "change" },
        ],
        contact: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        contact_number: [
            { required: true, message: "请输入联系人手机号", trigger: "blur" },
            {
                validator: (required: object, value: number, callback: any) =>
                    validator("phone", value, callback),
                message: "请输入正确的手机号",
                trigger: "blur",
            },
        ],
        mailing_address: [
            { required: true, message: "请输入邮寄地址", trigger: "blur" },
        ],
        shipping_methods: [
            { required: true, message: "请选择配送方式", trigger: "change" },
        ],
    };
    prev() {
        this.steps--;
    }
    next(formName: string) {
        // 验证表单
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate((valid: boolean): any => {
            if (!valid) return;
            this.steps++;
        });
    }
    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate((valid: boolean): any => {
            if (!valid) return;
        });
        this.add();
    }

    add() {
        const form = this.form;
        apiPhysicalVouchersAdd(form)
            .then(() => {
                this.$message.success("添加成功!");
                setTimeout(() => this.$router.go(-1), 500);
            })
            .catch(() => {
                this.$message.error(" !");
            });
    }
}
